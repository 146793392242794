export abstract class Tracker {
    _isInitialized = false;
    _isStopped = false;

    abstract init(): void;

    stop() {
        if (this._isInitialized) {
            this._isStopped = true;
        }
    }
}
