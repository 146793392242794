import { UrlParams } from "./urlParams";

const params = new URLSearchParams(window ? window.location.search : {});
export const step = params.get(UrlParams.STEP);

const forcedControlGroup = params.get(UrlParams.FORCE_CONTROL_GROUP);
const forcedTestGroup = params.get(UrlParams.FORCE_TEST_GROUP);
const forcedTest1Group = params.get(UrlParams.FORCE_TEST_1_GROUP);
const forcedTest2Group = params.get(UrlParams.FORCE_TEST_2_GROUP);
const forcedTest3Group = params.get(UrlParams.FORCE_TEST_3_GROUP);

export const countryCodeParam = params.get(UrlParams.COUNTRY_CODE);
export const utmCampaign = params.get(UrlParams.UTM_CAMPAIGN);
export const utmSource = params.get(UrlParams.UTM_SOURCE);
export const utmContent = params.get(UrlParams.UTM_CONTENT);
export const email = params.get(UrlParams.EMAIL);
export const uuid = params.get(UrlParams.USER_ID);
export const friendsUserId = params.get(UrlParams.FRIENDS_USER_ID);
export const friendsContactId = params.get(UrlParams.FRIENDS_CONTACT_ID);
export const testFlow = params.get(UrlParams.E2E_TEST_FLOW);
export const language = params.get(UrlParams.LANGUAGE);
export const withSessionReplay = params.get(UrlParams.SESSION_REPLAY) === "on";

export const generatedWorkoutUuid = params.get("generated_workout_uuid");

export const getForcedControlGroup = () => {
    return forcedControlGroup?.split(";") ?? [];
};

export const getForcedTestGroup = () => {
    return forcedTestGroup?.split(";") ?? [];
};

export const getForcedTest1Group = () => {
    return forcedTest1Group?.split(";") ?? [];
};

export const getForcedTest2Group = () => {
    return forcedTest2Group?.split(";") ?? [];
};

export const getForcedTest3Group = () => {
    return forcedTest3Group?.split(";") ?? [];
};

export const enum UTMSources {
    TikTok = "tiktok",
}
