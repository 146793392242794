export enum ExperimentIds {
    // do not remove this is permanent exp for splitting users into segments
    SPLIT_INTO_TWO_SEGMENTS = "webtest_split_into_two_segments",
    NEW_PAYMENT_VERSION = "webtest-palta-new-sdk-2",
    DISABLE_PREVENT_DUPLICATE_FLOW = "disable_prevent_duplicate_flow",
    NUMBER_OF_STEPS = "webtest_number_of_step_FAEX_509_aug24",
    AI_LAB_UPSELL = "webtest_ai_lab_upsell_FAEX_563_oct24",
    COMPARE_ZING_VS_COACH_ITER1 = "webtest_compare_zing_vs_coach_iter1_FAEX_568_oct24",
    AI_USER_SUMMARY = "webtest_ai_summary_FAEX_505_nov24",
    NEW_PAYWALL_SHORT = "webtest_new_paywall_short_FAEX_584_nov24",
    DYNAMIC_PRICES_YEAR_PLAN_WITH_TRIAL = "webtest_dynamic_prices_year_plan_with_trial_FAEX_583_nov24",
    SENIORS_FUNNEL = "webtest_seniors_funnel_FAEX_573_nov24",
    DISCLAIMER_DISCOVERY = "webtest_disclaimer_discovery_FAEX_582_nov24",
    NEW_PAYWALL_BOTTOM = "webtest_new_paywall_2nd_part_FAEX_584_dec24",
    ANDROID_DISCLAIMER = "webtest_disclaimer_FAEX_591_dec24",
}

export const enum ExperimentVariants {
    CONTROL = "control",
    TEST = "test",
    TEST_1 = "test_1",
    TEST_2 = "test_2",
    TEST_3 = "test_3",
    NOT_ALLOCATED = "not_allocated",
    SEGMENT_A = "segment_A",
    SEGMENT_B = "segment_B",
}

export const TEST_VARIANTS = [
    ExperimentVariants.TEST,
    ExperimentVariants.TEST_1,
    ExperimentVariants.TEST_2,
    ExperimentVariants.TEST_3,
];

// need to track paywall id for analytics
export const paywallExperimentsIds = [
    ExperimentIds.COMPARE_ZING_VS_COACH_ITER1,
    ExperimentIds.DYNAMIC_PRICES_YEAR_PLAN_WITH_TRIAL,
    ExperimentIds.DISCLAIMER_DISCOVERY,
    ExperimentIds.NEW_PAYWALL_SHORT,
    ExperimentIds.NEW_PAYWALL_BOTTOM,
    ExperimentIds.ANDROID_DISCLAIMER,
];
