import { matchPath } from "react-router-dom";

import { newBothContentRoutes } from "@/components/Both/utils";
import { Routes as AppRoutes } from "@/constants/routes";
import { Genders } from "@/types/gender";

const enum FunnelTypes {
    both = "both",
    friends = "friends",
    offer = "offer",
    calisthenics = "calisthenics",
}

type AllFunnelTypes = FunnelTypes | Genders;

export const getFunnelTypeForAnalytics = (gender: Genders): AllFunnelTypes | string => {
    const isBoth = matchPath(
        {
            path: `/${AppRoutes.Both}`,
            end: false,
        },
        window.location.pathname
    );
    const isBothGoogle = matchPath(
        {
            path: `/${AppRoutes.BothGoogle}`,
            end: false,
        },
        window.location.pathname
    );
    const isFriends = matchPath(
        {
            path: `/${AppRoutes.Both}/${AppRoutes.Friends}`,
            end: false,
        },
        window.location.pathname
    );
    const isOfferUrl = matchPath(
        {
            path: `/${AppRoutes.Both}/${AppRoutes.Offer}`,
            end: false,
        },
        window.location.pathname
    );
    const isCalisthenicsUrl = matchPath(
        {
            path: `/${AppRoutes.Calisthenics}`,
            end: false,
        },
        window.location.pathname
    );
    const newBothType =
        isBoth &&
        newBothContentRoutes.find((route) => window.location.pathname.split("/").includes(route));

    if (isBoth || isBothGoogle) {
        if (isFriends) return FunnelTypes.friends;
        if (isOfferUrl) return FunnelTypes.offer;
        if (newBothType) {
            return `${FunnelTypes.both}/${newBothType}`;
        }

        return FunnelTypes.both;
    } else if (isCalisthenicsUrl) {
        return FunnelTypes.calisthenics;
    }

    return gender;
};
