import React, {
    createContext,
    memo,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useMatch } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { setAnalyticsUserProperties } from "@/constants/amplitude";
import { ExperimentIds, ExperimentVariants, TEST_VARIANTS } from "@/constants/experiments";
import { Routes as AppRoutes } from "@/constants/routes";
import { useLocalization } from "@/hooks/useLocalization";
import { useGender } from "@/utils/GenderProvider";
import { useCurrency, useGeolocation } from "@/utils/GeolocationProvider";
import { Sentry } from "@/utils/trackers";
import * as EppoSdk from "@eppo/js-client-sdk";

import { localStorageManager, LocalStorageManagerKeys } from "./localStorageManager";
import {
    getForcedControlGroup,
    getForcedTest1Group,
    getForcedTest2Group,
    getForcedTest3Group,
    getForcedTestGroup,
} from "./settings";

interface ExperimentsContextType {
    enableExperiment: (
        experimentId: ExperimentIds | string,
        attributes?: Record<string, unknown>
    ) => void;
    getExperimentVariant: (experimentId: ExperimentIds | string) => ExperimentVariants;
}

export const ExperimentsContext = createContext<ExperimentsContextType>({
    enableExperiment: () => null,
    getExperimentVariant: () => ExperimentVariants.CONTROL,
});

export const getOrGenerateUuid = () => {
    let uuid = localStorageManager.getStoredItem<string>(LocalStorageManagerKeys.EPPO_UUID);

    if (!uuid) {
        uuid = uuidv4();
        localStorageManager.storeItem(LocalStorageManagerKeys.EPPO_UUID, uuid);
    }

    return uuid;
};

const uuid = getOrGenerateUuid();

type EnabledExperiments = Record<ExperimentIds | string, ExperimentVariants>;

const ExperimentsProvider = ({ children }: PropsWithChildren) => {
    const [enabledExperiments, setEnabledExperiment] = useState<EnabledExperiments>(
        {} as EnabledExperiments
    );

    const { language } = useLocalization();
    const { country } = useGeolocation();
    const { currency, isLoaded: isCurrencyLoaded } = useCurrency();
    const { gender } = useGender();

    useEffect(() => {
        setAnalyticsUserProperties({ eppo_uuid: uuid });
    }, []);

    const segment = useMemo(() => {
        const eppoClient = EppoSdk.getInstance();
        return eppoClient.getStringAssignment(
            ExperimentIds.SPLIT_INTO_TWO_SEGMENTS,
            uuid,
            {
                language,
                country,
                currency: isCurrencyLoaded && currency,
                gender,
            },
            ExperimentVariants.NOT_ALLOCATED
        ) as ExperimentVariants;
    }, [country, currency, gender, language, isCurrencyLoaded]);

    const enableExperiment = useCallback(
        (experimentId: ExperimentIds | string, attributes: Record<string, unknown> = {}) => {
            setEnabledExperiment((enabledExperiments) => {
                try {
                    if (!enabledExperiments[experimentId]) {
                        const attributesWithoutNullish = Object.entries(attributes).reduce(
                            (acc, [key, value]) => ({ ...acc, [key]: value ?? "" }),
                            {}
                        );
                        const eppoClient = EppoSdk.getInstance();
                        const variant = eppoClient.getStringAssignment(
                            experimentId,
                            uuid,
                            {
                                language,
                                country,
                                currency,
                                gender,
                                segment,
                                ...attributesWithoutNullish,
                            },
                            ExperimentVariants.NOT_ALLOCATED
                        ) as ExperimentVariants;

                        setAnalyticsUserProperties({ [experimentId]: `${variant}` });
                        return {
                            ...enabledExperiments,
                            [experimentId]: variant,
                        };
                    }
                } catch (e) {
                    Sentry.captureException(e, { experimentId });
                }

                return {
                    ...enabledExperiments,
                };
            });
        },
        [country, currency, gender, language, segment]
    );

    const getExperimentVariant = useCallback(
        (experimentId: ExperimentIds | string) => enabledExperiments[experimentId],
        [enabledExperiments]
    );

    return (
        <ExperimentsContext.Provider
            value={{
                enableExperiment,
                getExperimentVariant,
            }}
        >
            {children}
        </ExperimentsContext.Provider>
    );
};

interface ExperimentProps {
    experimentId: ExperimentIds;
}

const groupByForcedParams: Record<ExperimentVariants, string[]> = {
    [ExperimentVariants.CONTROL]: getForcedControlGroup(),
    [ExperimentVariants.TEST]: getForcedTestGroup(),
    [ExperimentVariants.TEST_1]: getForcedTest1Group(),
    [ExperimentVariants.TEST_2]: getForcedTest2Group(),
    [ExperimentVariants.TEST_3]: getForcedTest3Group(),
    [ExperimentVariants.NOT_ALLOCATED]: [],
    [ExperimentVariants.SEGMENT_A]: [],
    [ExperimentVariants.SEGMENT_B]: [],
};

export const useExperiment = ({ experimentId }: ExperimentProps) => {
    const { getExperimentVariant } = useContext(ExperimentsContext);
    const isForceGroup = useMemo(
        () =>
            Object.values(groupByForcedParams).some((group) =>
                group.some((exp) => Object.values(ExperimentIds).includes(exp as ExperimentIds))
            ),
        []
    );

    const assignedVariation = useMemo(() => {
        if (isForceGroup) {
            return (
                (Object.keys(groupByForcedParams) as ExperimentVariants[]).find((key) =>
                    groupByForcedParams[key].includes(experimentId)
                ) || ExperimentVariants.CONTROL
            );
        }
        return getExperimentVariant(experimentId);
    }, [getExperimentVariant, experimentId, isForceGroup]);

    return {
        variant: assignedVariation,
        isTest: !!assignedVariation && TEST_VARIANTS.includes(assignedVariation),
        uuid,
    };
};

export const useIsExperimentEnabledFunction = () => {
    const { getExperimentVariant } = useContext(ExperimentsContext);

    const isForceGroup = useMemo(
        () => Object.values(groupByForcedParams).some((group) => group.length),
        []
    );

    const getIsExperimentEnabled = (experimentId: ExperimentIds | string) => {
        let assignedVariation;
        if (isForceGroup) {
            assignedVariation =
                (Object.keys(groupByForcedParams) as ExperimentVariants[]).find((key) =>
                    groupByForcedParams[key].includes(experimentId)
                ) || ExperimentVariants.CONTROL;
        } else {
            assignedVariation = getExperimentVariant(experimentId);
        }

        return assignedVariation && TEST_VARIANTS.includes(assignedVariation);
    };

    return {
        getIsExperimentEnabled,
    };
};

export const useEnableExperiment = () => {
    const { enableExperiment } = useContext(ExperimentsContext);

    const isFriendsFlow = useMatch({
        path: `/:both/${AppRoutes.Friends}`,
        end: false,
    });

    const isEmailFlow = useMatch({
        path: `/${AppRoutes.PromoPaywall}`,
        end: false,
    });

    const isCalisthenicsUrl = useMatch({
        path: `/${AppRoutes.Calisthenics}`,
        end: false,
    });

    const isOfferUrl = useMatch({
        path: `/${AppRoutes.Both}/${AppRoutes.Offer}`,
        end: false,
    });

    const isSeniorsUrl = useMatch({
        path: `/${AppRoutes.Both}/${AppRoutes.Seniors}`,
        end: false,
    });

    const handleEnableExperiment = useCallback(
        ({
            experimentId,
            enableCondition = true,
            attributes = {},
            enableOnSeparateFlows = !isFriendsFlow &&
                !isEmailFlow &&
                !isOfferUrl &&
                !isCalisthenicsUrl,
        }: {
            experimentId: ExperimentIds | string;
            enableCondition?: boolean;
            attributes?: Record<string, unknown>;
            enableOnSeparateFlows?: boolean;
        }) => {
            if (isSeniorsUrl && experimentId !== ExperimentIds.SENIORS_FUNNEL) {
                return;
            }
            if (enableCondition && enableOnSeparateFlows) {
                enableExperiment(experimentId, attributes);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [enableExperiment]
    );

    return {
        enableExperiment: handleEnableExperiment,
    };
};

export default memo(ExperimentsProvider);
